@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a, span, div, button, input, label {
  font-family: 'Inter', sans-serif;
}

.products-container {
  margin-top: 20px;
}

.products-title {
  text-align: center;
  margin-top: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
}

.category-list {
  margin-top: 20px;
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.product-image {
  height: 200px;
  object-fit: cover;
}

.product-name {
  font-size: 1.25rem;
  font-weight: 600;
}

.product-price {
  font-size: 1rem;
  color: #666;
}

.product-button {
  background-color: #43b7e5;
  color: white;
  transition: background-color 0.3s, border-color 0.3s;
}

.product-button:hover {
  background-color: #36a3cc;

}

.product-button:active {
  background-color: #2d8fab;

}
