body {
  background-color: #f8f8f8; /* Fondo claro para toda la página */
  font-family: 'Inter', sans-serif; /* Fuente de la página */
  margin: 0; /* Eliminar margen del body */
  padding: 0; /* Eliminar padding del body */
}

.top-bar {
  animation: colorChange 15s infinite;
  height: 30px; /* Menor altura */
  display: flex; /* Usar flexbox para centrar verticalmente */
  align-items: center; /* Centrar verticalmente */
  justify-content: center; /* Centrar horizontalmente */
  z-index: 1100; /* Asegura que la barra superior esté siempre encima */
  box-shadow: none; /* Eliminar la sombra */
  color: black; /* Texto negro */
}

.app-bar {
  height: 95px; /* Ajustar la altura de la barra de menú */
  display: flex; /* Usar flexbox para centrar verticalmente */
  align-items: center; /* Centrar verticalmente */
  justify-content: space-between; /* Distribuir espacio entre elementos */
  z-index: 1000; /* Asegura que la barra de menú esté siempre encima del contenido */
  box-shadow: none; /* Eliminar la sombra */
  top: 30px; /* Posicionar debajo de la barra superior */
  color: black; /* Texto negro */
  background-color: #ffffff; /* Fondo blanco */
  padding: 0 20px; /* Añadir padding para mejor apariencia */
  transition: margin-left 0.3s ease; /* Transición suave para desplazamiento */
}

.app-bar-shift {
  margin-left: 300px; /* Desplazar la barra cuando el carrito esté visible */
}

.app-bar-text {
  font-size: 14px; /* Letra más pequeña */
  color: black; /* Texto negro */
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu-logo {
  height: 80px; /* Ajustar la altura de la imagen */
  margin-right: 20px; /* Espacio entre la imagen y el texto */
  margin-top:5px;
  display: block; /* Asegurar que la imagen se vea siempre */
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 20px; /* Tamaño de letra más grande para el título */
  color: black; /* Texto negro */
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-links a,
.menu-button {
  color: black !important; /* Asegurar que los enlaces y botones tengan texto negro */
  text-decoration: none;
}

.hamburger-menu {
  display: none; /* Ocultar menú hamburguesa en escritorio */
  color: black !important; /* Asegurar que el ícono sea visible en el fondo blanco */
}

@keyframes colorChange {  
  0% { background-color: #BCF6CE; }
  25% { background-color: #BFE73C; }
  50% { background-color: #B2D7F5; }
  75% { background-color: #43b7e5; }
  100% { background-color: #E8F8C4; }
}

.content {
  margin-top: 120px; /* Asegura espacio suficiente para la barra superior y la barra de navegación */

  transition: margin-left 0.3s ease; /* Transición suave para desplazamiento */
}



.cart-aside {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  background: white;
  border-left: 1px solid #ccc;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  z-index: 1200; /* Asegura que el carrito de compras esté por encima de las barras */
}

/* Estilos adicionales para responsividad */
@media (max-width: 768px) {
  .app-bar {
    justify-content: space-between; /* Asegura espacio entre elementos en móvil */
  }

  .nav-links {
    display: flex;
    gap: 10px; /* Ajustar el espacio entre enlaces en móvil */
  }

  .menu-logo {
    height: 60px; /* Ajustar tamaño de logo en móvil */
  }

  .menu-button {
    display: block; /* Mostrar botón de carrito en móvil */
  }

  .hamburger-menu {
    display: block; /* Mostrar menú hamburguesa en móvil */
    color: black !important; /* Asegurar que el ícono sea visible en el fondo blanco */
  }
}

.badge {
  .MuiBadge-badge {
    background-color: #FF69B4; /* Color rosado para el badge */
  }

  .MuiBadge-badge[badgeContent="0"] {
    background-color: #B0B0B0; /* Color gris para badge cuando no hay elementos */
  }
}
